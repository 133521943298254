import React from 'react';



class InterestList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            width: (this.props.size ? this.props.size : '25px'),
            height: (this.props.size ? this.props.size : '25px')
        }
    }
    render(){
        return (
        <td>{this.props.interests.map(e => (
            <img src={process.env.REACT_APP_SERVER + e.img} width={this.state.width} height={this.state.height}></img>
        ))}</td>
        )
    }
}

export default InterestList;