import React from 'react';
import Axios from 'axios';




class LearningInput extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            benefits: (props.existing ? props.existing : []),
            name: "",
            color: "",
            uuid: null,
            suggestions: [],
            editing: false
        }
        this.componentDidMount = this.componentDidMount.bind(this);
        this.addBenefit = this.addBenefit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.removeBenefit = this.removeBenefit.bind(this);
        this.getBenefitSuggestions = this.getBenefitSuggestions.bind(this);
        this.addFromSuggestions = this.addFromSuggestions.bind(this);
        this.editSuggestion = this.editSuggestion.bind(this);
        this.clearInputs = this.clearInputs.bind(this);
        this.stopEditing = this.stopEditing.bind(this);
        
    }
    componentDidMount(){
        this.getBenefitSuggestions();
    }
    clearInputs(){
        this.setState({
            name: "",
            color: "",
            uuid: null
        })
    }
    addBenefit(e){
        e.preventDefault();

        if(this.state.name == "" || this.state.color == "") return;

        if(this.state.editing){
            // edit current one

            // Change color & name of suggestions artificially
            let oldSuggestions = this.state.suggestions;

            oldSuggestions.forEach((e, i) => {
                if(e.uuid == this.state.uuid) oldSuggestions[i] = {uuid: e.uuid, name: this.state.name, color: this.state.color};
            });

            let oldBenefits = this.state.benefits;
            let index = oldBenefits.map(e => e.uuid).indexOf(this.state.uuid);

            if(index !== -1){
                oldBenefits[index] = {uuid: this.state.uuid, name: this.state.name, color: this.state.color};
            }else{
                oldBenefits.push({uuid: this.state.uuid, name: this.state.name, color: this.state.color});
            }
            this.setState({
                suggestions: oldSuggestions,
                benefits: oldBenefits, 
                uuid: null,
                name: "",
                color: "",
                editing: false
            });
        }else{
            // add a new benefit
            
            // check if the name exists
            let nameexists = this.state.benefits.map(e => e.name.toLowerCase()).indexOf(this.state.name.toLowerCase())
            if(nameexists !== -1) return;

            let oldBenefits = this.state.benefits;

            oldBenefits.push({
                name: this.state.name,
                color: this.state.color
            });

            this.setState({
                benefits: oldBenefits,
                uuid: null,
                name: "",
                color: ""
            })
            

        }


        if(this.props.onChange){
            this.props.onChange({
                "target": {
                    "name": "learning_input"
                },
                "value": this.state.benefits
            })
        }
    }

    addFromSuggestions(name,color, uuid){
        let oldBenefits = this.state.benefits;
        let existing_index;

        oldBenefits.forEach((e,i) => {
            if(e.name == name){
                existing_index = i;
            }
        })

        if(existing_index){
            oldBenefits[existing_index] = {name: name, color: color, uuid: oldBenefits[existing_index].uuid};
        }else{
            oldBenefits.push({
                name: name,
                color: color,
                uuid: uuid
            })
        }

        this.setState({benefits: oldBenefits});

        if(this.props.onChange){
            this.props.onChange({
                "target": {
                    "name": "learning_input"
                },
                "value": this.state.benefits
            })
        }
    }

    handleChange(e){
        if(e.target.name == "color"){
            this.setState({color: e.target.value});
            return;

        }
        if(e.target.name == "text"){
            this.setState({name: e.target.value});
            return;
        }
        
    }
    removeBenefit(name){
        let index;
        this.state.benefits.forEach((e, i) => {
            if(e.name == name) index = i;
            console.log(e.name);
        })
        if(index !== null){
            let oldBenefits = this.state.benefits;
            oldBenefits.splice(index, 1);

            this.setState({benefits: oldBenefits});
        }
    }
    editSuggestion(name){
        this.state.suggestions.forEach((e, i) => {
            if(name == e.name){
                this.setState({
                    name: e.name,
                    color: e.color,
                    uuid: e.uuid,
                    editing: true
                })
            }
        })
    }

    stopEditing(){
        this.setState({name: "", color: "", uuid: null, editing: false});
    }

    getBenefitSuggestions(){
        Axios.get(`${process.env.REACT_APP_SERVER}/api/admin/learningbenefits`, {withCredentials: true}).then((data) => {
            this.setState({suggestions: data.data.data});
        }).catch((error) => {
            console.log(error);
        })
    }
    render(){
        return (
            <div className="learning__input">
                <label className="label">Learning Benefits</label>

                <div className="tags has-addons">
                    {
                        this.state.benefits.sort((a,b) => {
                            a = a.name.toLowerCase();
                            b = b.name.toLowerCase();

                            return a < b ? -1 : a < b ? 1 : 0;
                        }).map(e => (
                            <span>
                                <span className="tag" style={{"background": e.color, "color": "white", "verticalAlign": "top"}}>
                                {e.name}
                                
                                </span>
                                <a className="tag is-delete" onClick={a => this.removeBenefit(e.name)}></a>
                                </span>
                        ))
                    }
                </div>

                <div className="learning__add">
                    <form className="learning__add__form" onSubmit={this.addBenefit}>
                        <input name="text" value={this.state.name} onChange={this.handleChange} type="text" style={{"width": "20%"}} className="input" placeholder="Benefit Name..."></input>
                        <input name="color" value={this.state.color} onChange={this.handleChange} type="color" className="color__input"></input>

                        {
                            this.state.editing ? (
                                <span>
                                <input type="submit" value="Update Learning Benefit" style={{"marginLeft": "10px"}} className="button is-primary"></input>
                                <input type="submit" onClick={this.stopEditing} value="Reset" className="button is-danger"></input>  
                                </span>
                            ): (  <input type="submit" value="Add Learning Benefit" style={{"marginLeft": "10px"}} className="button is-primary"></input>)
                            
                        }
                    </form>
                </div>
                <div className="learning__suggestions">
                    <span className="label">Suggestions</span>

                    <div className="tags has-addons">

                        {
                            this.state.suggestions.sort((a,b) => {
                                a = a.name.toLowerCase();
                                b = b.name.toLowerCase();

                                return a < b ? -1 : a < b ? 1 : 0;
                            }).map(e => (
                                    <span style={{"margin": "5px"}}>
                                        <span onClick={event => this.addFromSuggestions(e.name, e.color, e.uuid)} className="tag" style={{"background": e.color, "color": "white", "verticalAlign": "top",  "cursor": "pointer"}}>
                                        {e.name}
                                        </span>
                                        <a className="tag is-dark" onClick={event => this.editSuggestion(e.name)} style={{"verticalAlign": 'top'}}>

                                            Edit
                                        
                                        </a>
                                    </span>
                            ))
                        }


                    </div>
                </div>
            </div>
        )
    }
}

export default LearningInput;