import React from 'react';
import '../App.css';
import 'react-bulma-components/dist/react-bulma-components.min.css';

import { Button, Section, Container, Columns, Menu  } from "react-bulma-components";


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class Activites extends React.Component{
  constructor(){
    super();
  }
  render(){
    return (
      <h1>Activities</h1>
    )
  }
}


export default Activites;
