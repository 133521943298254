import React from 'react';
import '../../App.js';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import Axios from 'axios';
import Moment from 'react-moment';

import { CSVLink, CSVDownload } from "react-csv";



import {  Table, Section, Box, Level, Button } from "react-bulma-components";


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class Devices extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      values: [],
      search: "",
      searchResults: [],
      unformatted: []
    }
    this.componentDidMount = this.componentDidMount.bind(this);
    this.getRecords = this.getRecords.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

  }

  componentDidMount(){
    this.getRecords();
  }

  getRecords(){

    Axios.get(`${process.env.REACT_APP_SERVER}/api/admin/stats/devices`, {withCredentials: true}).then((e) => {
      let users = [];
      e.data.data.forEach((element,i) => {
        element.users.forEach((user,i) => {
          users.push({user_id: user.uuid, month: user.month, year: user.year, device_uuid: element.uuid, created_at: element.dates.created_at});
        })
      })
      console.log(users);
      this.setState({
        values: users,
        searchResults: users,
        unformatted: e.data.data
      })
    }).catch((e) => {
      console.log(e);
    })

  }
  handleSearch(e){
    
    this.setState({
      search: e.target.value
    })

    if(e.target.value === "" || e.target.value === " "){
      this.setState({searchResults: this.state.values});
      return;
    }
    
    let filt = this.state.values.filter(obj => obj.activity_title.toLowerCase().includes(this.state.search.toLowerCase()));
    this.setState({searchResults: filt});
  }
  render(){
    return (
      <Section>
        <Box>
              <Level renderAs="nav">
                  <Level.Side align="left">
                      <Level.Item>
                          <h1>Devices & Users</h1>
                          <Button onClick={this.getRecords} style={{"marginLeft": "10px"}}>Refresh</Button>
                      </Level.Item>
                  </Level.Side>
                  <Level.Side align="center">
                    <span><b>{this.state.searchResults.length}</b> User(s) <b>{this.state.unformatted.length}</b> Device(s)</span>
                  </Level.Side>
                  <Level.Side align="right">
                      <Level.Item>

                      <CSVLink className="button is-primary" filename={"users.csv"} data={this.state.values}>
                        Download CSV
                    </CSVLink>

                      </Level.Item>
                  </Level.Side>
              </Level>
        </Box>


        <Table className="stat__table">
          <thead>
            <tr>
              <th>User ID</th>
              <th>Device ID</th>
              <th>Age</th>
              <th>Joined</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.searchResults.map(e => (
                <tr>
                  <td>{e.user_id}</td>
                  <td>{e.device_uuid}</td>
                  <td><Moment parse="YYYY-MM" fromNow ago>{e.year + "-" + e.month}</Moment></td>
              <td><Moment fromNow>{e.created_at}</Moment></td>
                </tr>
              ))
            }
          </tbody>
        </Table>

      </Section>
    )
  }
}


export default Devices;
