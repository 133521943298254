import React from 'react';
import '../../App.js';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import Axios from 'axios';
import Moment from 'react-moment';
import { CSVLink, CSVDownload } from "react-csv";


import {  Table, Section, Box, Level, Button } from "react-bulma-components";


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import InterestList from '../../components/InterestList.js';

class ActivityTime extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      values: [],
      search: "",
      searchResults: [],
      page_number: 0,
      max_page: 0
    }
    this.componentDidMount = this.componentDidMount.bind(this);
    this.getRecords = this.getRecords.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

  }

  componentDidMount(){
    this.getRecords();
  }

  getRecords(){

    Axios.get(`${process.env.REACT_APP_SERVER}/api/admin/stats/activity`, {withCredentials: true}).then((e) => {
      
      let vals = [];
      

      e.data.data.forEach((e,i) => {

        let index = vals.map(el => el.device_uuid + "-" + el.activity_uuid).indexOf(e.device_uuid + "-" + e.activity_uuid);

        
        if(index == -1){
          e.users = 1;
          vals.push(e);
        }else if(index != -1){
          vals[index].users++;
        }
      })


      this.setState({
        values: vals,
        searchResults: vals
        
      })
      console.log(this.state);
    }).catch((e) => {
      console.log(e);
    })

  }
  handleSearch(e){
    
    this.setState({
      search: e.target.value
    })

    if(e.target.value === "" || e.target.value === " "){
      this.setState({searchResults: this.state.values});
      return;
    }
    
    let filt = this.state.values.filter(obj => obj.activity_title.toLowerCase().includes(this.state.search.toLowerCase()));
    this.setState({searchResults: filt});
  }
  render(){
    return (
      <Section>
        <Box>
              <Level renderAs="nav">
                  <Level.Side align="left">
                      <Level.Item>
                          <h1>Activity Time Spent</h1>
                          <Button onClick={this.getRecords} style={{"marginLeft": "10px"}}>Refresh</Button>
                      </Level.Item>
                  </Level.Side>
                  <Level.Side align="center">
                    <span><b>{this.state.searchResults.length}</b> Records(s)</span>
                    <CSVLink className="button is-primary" filename={"activity-time.csv"} data={this.state.values}>
                        Download CSV
                    </CSVLink>
                  </Level.Side>
                  <Level.Side align="right">
                      <Level.Item>

                        <form>
                            <div className="field" style={{"display": "inline-block"}}>
                                <div className="control">
                                    <input className="input" onChange={this.handleSearch} value={this.state.search} name="search" type="text" placeholder="Search by Activity title..."></input>
                                </div>
                            </div>
                        </form>

                      </Level.Item>
                  </Level.Side>
              </Level>
        </Box>


        <Table className="stat__table">
          <thead>
            <tr>
              <th>Activity Title</th>
              <th>Device ID</th>
              <th>Interests</th>
              <th>No. Users</th>
              <th>Time Spent</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.searchResults.map(e => (
                <tr>
                  <td>{e.activity_title}</td>
                 
                  <td>{e.device_uuid}</td>
                  {e.interests ? <InterestList interests={e.interests}></InterestList> : <td>None</td>}
                  <td>{e.users}</td>
                  <td>{e.value + "min"}</td>
                  <td><Moment fromNow>{e.created_at}</Moment></td>
                </tr>
              ))
            }
          </tbody>
        </Table>

       
      </Section>
    )
  }
}


export default ActivityTime;
