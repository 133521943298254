import React from 'react';
import axios from 'axios';


class InterestInput extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            interests: [],
            existing: (this.props.existing ? this.props.existing.map(e => e.id) : [])
        }
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadInterests = this.loadInterests.bind(this);
        this.selectInterest = this.selectInterest.bind(this);
    }

    componentDidMount(){
        this.loadInterests();
    }

    loadInterests(){
        axios.get(`${process.env.REACT_APP_SERVER}/api/admin/interests`, {withCredentials: true}).then((e) => {
            e.data.data.splice(0,1);

            if(this.state.existing.length > 0){
                
                e.data.data.forEach((e,i) => {
                    console.log(e.id);
                    console.log(this.state.existing);
                    if(this.state.existing.indexOf(e.id) !== -1){
                        console.log('assdasd');
                        e.selected = true;
                    }
                })
            }

            this.setState({
                interests: e.data.data
            })

            this.props.onChange({
                target: {
                    name: "interest_input"
                },
                value: this.state.interests.filter(e => e.selected)
            })

        }).catch((e) => {
            console.error('Error whilst loading Interests');
        })
    }

    selectInterest(e){

       let interest = e.currentTarget.getAttribute('data-name')

        this.state.interests.forEach((e, i) => {
            console.log(interest)
            if(e.short == interest){
                
                let newInterests = this.state.interests;

                if(newInterests[i].selected){
                    newInterests[i].selected = false;
                }else{
                    newInterests[i].selected = true;
                }
                this.setState({
                    interests: newInterests
                })
            }
        })


        if(this.props.onChange){
            this.props.onChange({
                target: {
                    name: "interest_input"
                },
                value: this.state.interests.filter(e => e.selected)
            })
        }

    }

    render(){
        return (
            
            <div className="interest__input">

                <label class="label">Interest Groups</label>

                <div>
                    {
                        this.state.interests.map((e) => (
                            <div onClick={this.selectInterest} className={e.selected ? "interest__input__item selected": "interest__input__item"} data-name={e.short} style={{'display': 'inline-block'}}>
                                <img src={process.env.REACT_APP_SERVER + e.img} width="25px" height="25px"></img>
                                <span>{e.name}</span>
                            </div>
                            
                        ))
                    }
                </div>

                <span>{this.state.interests.filter(e => e.selected).length} Interest Groups Selected.</span>
            </div>

        )
    }
}

export default InterestInput;