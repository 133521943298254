import React from 'react';
import '../../App.js';
import 'react-bulma-components/dist/react-bulma-components.min.css';

import {  Table, Section, Box, Level, Button } from "react-bulma-components";

import MaterialList from '../../components/MaterialList';
import InterestList from '../../components/InterestList';


import axios from 'axios';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Axios from 'axios';

class ActivityList extends React.Component{
  constructor(props){
    super();

    this.state = {
      activities: [], 
      searchResults: [],
      search: ""
    }

    this.componentDidMount = this.componentDidMount.bind(this);
    this.getAllActivities = this.getAllActivities.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClick = this.handleClick.bind(this);

  }
  componentDidMount(){
    this.getAllActivities();
  }
  getAllActivities(){

    axios.get(`${process.env.REACT_APP_SERVER}/api/v1/activities`).then((e) => {
      this.setState({
        activities: e.data.data,
        searchResults: e.data.data
      });
      
    }).catch((e) => {
      console.error('Error whilst loading activities');
    })
        
  }

  handleSearch(e){
    
    this.setState({
      search: e.target.value
    })

    if(e.target.value === "" || e.target.value === " "){
      this.setState({searchResults: this.state.activities});
      return;
    }
    
    let filt = this.state.activities.filter(obj => obj.title.toLowerCase().includes(this.state.search.toLowerCase()));
    this.setState({searchResults: filt});
  }

  handleClick(e){
    this.props.history.push('/edit/' + e.target.name);
  }

  render(){
    return (
      <Section>
          <Box>
              <Level renderAs="nav">
                  <Level.Side align="left">
                      <Level.Item>
                          <h1>Activity List</h1>
                          <Button onClick={this.getAllActivities} style={{"marginLeft": "10px"}}>Refresh</Button>
                      </Level.Item>
                  </Level.Side>
                  <Level.Side align="right">
                      <Level.Item>

                        <form>
                            <div className="field" style={{"display": "inline-block"}}>
                                <div className="control">
                                    <input className="input" onChange={this.handleSearch} value={this.state.search} name="search" type="text" placeholder="Search by title..."></input>
                                </div>
                            </div>
                        </form>

                      </Level.Item>
                  </Level.Side>
              </Level>
          </Box>

          <Table className="activity-list-table">
              <thead>
                  <tr>
                    <th>Title</th>
                    <th>Age Range</th>
                    <th>Materials</th>
                    <th>Interests</th>
                    <th></th>
                  </tr>
              </thead>
              <tbody>
                  {
                    this.state.searchResults.map(e => (
                    <tr>
                      <td>{e.title}</td>
                      <td>{e.ages.min} - {e.ages.max}</td>
                      <MaterialList materials={e.materials}></MaterialList>
                      <InterestList interests={e.interests}></InterestList>
                      <td className="last"><Button name={e.uuid} onClick={this.handleClick}>Edit</Button></td>
                    </tr>
                    ))
                  }
              </tbody>
          </Table>
      </Section>
    )
  }
}


export default ActivityList;
