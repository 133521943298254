import React from 'react';
import axios, {post} from 'axios';


class FileUpload extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            file: "",
            isDisabled: false,
            file_dest: "",
            file_name: "Choose a file…"
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.fileInput = React.createRef();
        this.reset = this.reset.bind(this);
    }
    handleSubmit(e){
        e.preventDefault();

        if(this.state.isDisabled == true) return;

        this.setState({
            isDisabled: true
        });

        this.fileUpload(this.fileInput.current.files[0]).then((response) => {


            this.setState({
                isDisabled: false,
                file_name: this.fileInput.current.files[0].name,
                file_dest: `${process.env.REACT_APP_SERVER}/resources/img/uploads/${response.data.file}`
            });

            // file is uploaded

            if(this.props.onChange){
                this.props.onChange({
                    "target": {
                        "name": "file_input"
                    },
                    "value": this.state.file_dest
                })
            }

        }).catch((err) => {
            this.setState({
                isDisabled: false
            })
        })

    }
    fileUpload(file){
        const url = `${process.env.REACT_APP_SERVER}/api/admin/upload`;
        const formData = new FormData();
        formData.append('files', file);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            withCredentials: true
        }

        return post(url, formData, config);


    }

    reset(){
        this.setState({
            file: "",
            isDisabled: false,
            file_dest: "",
            file_name: "Choose a file..."
        });

        if(this.props.onChange){
            this.props.onChange({
                "target": {
                    "name": "file_input"
                },
                "value": this.state.file_dest
            })
        }

        return;
    }

    handleChange(e){
        if(this.fileInput.current.files.length > 0){
            this.setState({
                file_name: this.fileInput.current.files[0].name
            })
        }else{
            this.setState({
                file_name: ""
            });
        }
        
    }
   
    render(){
        return (
            <form className="file__upload" onSubmit={this.handleSubmit}>     
                <p className="control">
                    <div className="file">
                        <label className="file-label">
                            <input className="file-input" accept="image/*" onChange={this.handleChange} type="file" ref={this.fileInput} name="resume"></input>
                            <span className="file-cta">
        <span className="file-icon">{
            this.state.file_dest != "" ? (<img src={this.state.file_dest}></img>) : ""
        }</span>
                            
                                <span className="file-label">
                                    {this.state.file_name}
                                </span>
                            </span>
                        </label>
                    </div>
                </p>
                <button type="submit"  className={this.state.isDisabled ? 'button is-primary is-loading': 'button is-primary'}>Upload</button>
            </form>
        )
    }
}

export default FileUpload;