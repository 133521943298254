import React from 'react';
import axios from 'axios';


class MaterialInput extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            materials: (props.materials ? JSON.parse(JSON.stringify(props.materials)) : []),
            fromProps: (props.materials ? true: false)
        }
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadMaterials = this.loadMaterials.bind(this);
        this.selectMaterial = this.selectMaterial.bind(this);
        this.reset = this.reset.bind(this);

    }

    componentDidMount(){
        console.log(this.props);
        if(this.props.materials == null) this.loadMaterials();
        
    }

    loadMaterials(){
        axios.get(`${process.env.REACT_APP_SERVER}/api/admin/materials`, {withCredentials: true}).then((e) => {
            
            
            e.data.data.sort((a,b) => {
                a = a.name.toLowerCase();
                b = b.name.toLowerCase();
                return (a < b ? -1 : a > b ? 1 : 0);
            })
 
            
            this.setState({
                materials: e.data.data
            })

            
        }).catch((e) => {
            console.error('Error whilst loading materials');
        })
    }

    reset(){
        let newMaterials = [];

        this.state.materials.forEach((e, i) => {
            let mat = JSON.parse(JSON.stringify(e));

            mat.selected = false;

            newMaterials.push(mat);
        });

        this.setState({
            materials: newMaterials
        })
        if(this.props.onChange){

            console.log('sending onchange reset');

            //console.log(newMaterials.filter(e => e.selected));
            this.props.onChange({"target": {
                "name": "material_input"
            },"value":newMaterials.filter(e => e.selected)});
        }

    }

    selectMaterial(e){

        

       let material = e.currentTarget.getAttribute('data-name')


        this.state.materials.forEach((e, i) => {
            if(e.short == material){
                
                
                let newMaterials = this.state.materials;

                if(newMaterials[i].selected){
                    console.log(e.short + " has been disabled");
                    newMaterials[i].selected = false;
                }else{
                    newMaterials[i].selected = true;
                    console.log(e.short + " has been enabled");
                }
                
                this.setState({
                    materials: newMaterials
                })

                
            }
        })
        if(this.props.onChange){
            this.props.onChange({"target": {
                "name": "material_input"
            },"value":this.state.materials.filter(e => e.selected)});
        }
        

    }

    static getDerivedStateFromProps(nextProps, prevState){

        if(nextProps.existing){
            let nextPropsu = JSON.parse(JSON.stringify(nextProps));
            let prevStateu = JSON.parse(JSON.stringify(prevState));

            nextPropsu.existing = nextPropsu.existing.map(e => e.id);

            let newstate = prevStateu.materials;
            let realchanges = false;
           
            newstate.forEach((e, i) => {
                if(nextPropsu.existing.indexOf(e.id) !== -1 && !e.updatedfromstate){

                    e.selected = true;
                    e.updatedfromstate = true;
                    realchanges = true;
                }
            })


            if(realchanges && nextProps.onChange){
               
                nextProps.onChange({"target": {
                    "name": "material_input"
                },"value":newstate.filter(e => e.selected)});
            }

            return {
                materials: newstate
            }

        }

        if(nextProps.materials){

            nextProps = JSON.parse(JSON.stringify(nextProps));
            prevState = JSON.parse(JSON.stringify(prevState));

            let newState = [];

            nextProps.materials.forEach((prop,i) => {
                let occured_index = null;
                prevState.materials.forEach((stat,index) => {
                   if(stat.short === prop.short){
                        occured_index = index;
                   }
                })              
                if(occured_index == null){
                    
                    prop.selected = false;
                    newState.push(prop);
                } else{
                    newState.push(prevState.materials[occured_index]);
                }
            })

            return {
                materials: newState
            }
        }else{
            return;
        }
        
    }

    render(){
        return (
            
            <div>

                <label class="label">Materials Required</label>

                <div>
                    {
                        this.state.materials.map((e) => (
                            <div onClick={this.selectMaterial} className={e.selected ? "material__input__item selected": "material__input__item"} data-name={e.short} style={{'display': 'inline-block'}}>
                                <img src={process.env.REACT_APP_SERVER + e.img} width="25px" height="25px"></img>
                                <span>{e.name}</span>
                            </div>
                            
                        ))
                    }
                </div>

                <span>{this.state.materials.filter(e => e.selected).length} Materials Selected.</span>
            </div>

        )
    }
}

export default MaterialInput;