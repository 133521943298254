import React from 'react';
import '../../App.js';

import 'react-bulma-components/dist/react-bulma-components.min.css';

import { Section, Heading  } from "react-bulma-components";

import {useParams} from 'react-router-dom';

import MaterialInput from "../../components/MaterialInput";

import InterestInput from "../../components/InterestInput";
import LearningInput from "../../components/LearningInput";
import StepInput from "../../components/StepInput";
import FileUpload from "../../components/FileUpload";



import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Axios from 'axios';

class EditActivity extends React.Component{
  constructor(props){
    super(props);

    //let {uuid} = useParams();


    this.state = {
      uuid: props.match.params.uuid,
      materials: [],
      title: "",
      description: "",
      time_group: 1,
      location: 1,
      age_min: 0,
      age_max: 99,
      image: "",
      interests:[],
      learning_benefits: [],
      steps: [],
      formissubmitting: false,
      activity_existing_materials: [],
      isDeleting: false,
      activity_exitsting_interests: [],
      hasloaded: false
      //uuid: uuid
    }

    this.materialsChanged = this.materialsChanged.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.deleteActivity = this.deleteActivity.bind(this);
  }
  materialsChanged(materials){
      console.log('materials?');
    this.setState({materials: materials.value});

  }

  deleteActivity(){
    if(window.confirm("Are you sure you want to remove this activity?")){
      this.setState({isDeleting: true});
      Axios.get(`${process.env.REACT_APP_SERVER}/api/admin/delete?uuid=${this.state.uuid}`, {withCredentials: true}).then((e) => {
        this.props.history.push('/');
        this.setState({isDeleting: false});
      }).catch((e) => {
        //console.error('');
        this.setState({isDeleting: false});
      })
    }
    
  }

  componentDidMount(){
    console.info('Getting activity information...');


    Axios.get(`${process.env.REACT_APP_SERVER}/api/v1/activity/${this.state.uuid}`).then((e) => {
        console.info('Activity found.');
        let activity_obj = e.data.data;




        activity_obj.steps.sort((a,b) => {return a.index - b.index}).forEach((step,i_step) => {
            step.materials.forEach((material, i_material) => {
                activity_obj.materials.forEach((full_material,i )=> {
                    if(full_material.id == material){
                        activity_obj.steps[i_step].materials[i_material] = full_material;
                    }
                })
            })
        })

        this.setState({
            age_max: activity_obj.ages.max,
            age_min: activity_obj.ages.min,
            description: activity_obj.description,
            image: activity_obj.image,
            title: activity_obj.title,
            time_group: activity_obj.time_group.id,
            // location_group: activity_obj.location_group,
            location: activity_obj.location_group,
            activity_existing_materials: activity_obj.materials,
            learning_benefits: activity_obj.learning_benefits,
            activity_exitsting_interests: activity_obj.interests,
            hasloaded: true,
            steps: activity_obj.steps
        })

    }).catch((e) => {
        console.error('Could not find activity.');
    })

  }

  handleSubmit(e){
    e.preventDefault();
    
    if(this.state.title == "") return;

    if(e.target.className == "add__new__activity" && this.state.formissubmitting == false){

      console.info("Saving Activity...");

      this.setState({
        formissubmitting: true
      });

      let steps = this.state.steps;

      steps.forEach((e,i) => {
        e.materials = e.materials.map(e => e.id);
      })

      Axios.post(`${process.env.REACT_APP_SERVER}/api/admin/createactivity`,{
        "title": this.state.title,
        "description": this.state.description,
        "time_group": this.state.time_group,
        "interests": this.state.interests.map(e => e.id),
        "learning_benefits": this.state.learning_benefits,
        "image": this.state.image,
        "materials": this.state.materials.map(e => e.id),
        "ages": {
          "max": this.state.age_max,
          "min": this.state.age_min
        },
        "location_group": this.state.location,
        "steps": steps,
        "uuid": this.state.uuid
      }, {withCredentials: true}).then((data) => {

        this.setState({
          formissubmitting: false
        })

        this.props.history.push('/');

        console.info("Activity saved");
      }).catch(e=>{
        this.setState({
          formissubmitting: false
        })
        console.error("Error Whilst saving activity");
      })
    }
  }

  handleChange(e){
    if(e.target.name == "title"){
      this.setState({
        title: e.target.value
      })
    };

    if(e.target.name == "text"){
      this.setState({
        description: e.target.value
      })
    }

    if(e.target.name == "time_group"){
      this.setState({
        time_group: parseInt(e.target.value)
      })
    }

    if(e.target.name == "location"){
      this.setState({
        location: parseInt(e.target.value)
      })
    }

    if(e.target.name == "age_max"){
      this.setState({
        age_max: e.target.value
      })
    }
    if(e.target.name == "age_min"){
      this.setState({
        age_min: e.target.value
      })
    }

    if(e.target.name == "file_input"){
      this.setState({
        image: e.value
      });
    }
    if(e.target.name == "interest_input"){
      this.setState({
        interests: e.value
      })

    }

    if(e.target.name =="learning_input"){
      this.setState({
        learning_benefits: e.value
      })
    }
    if(e.target.name == "step_input"){
      this.setState({
        steps: e.value
      });
    }
  }

  render(){
    return (
      <Section>
        <Heading size={5}>
          Edit Activity {this.state.uuid}
        </Heading>

        <form onSubmit={this.handleSubmit} className="add__new__activity">
          <div className="field">
            <label className="label">Title</label>
            <p className="control">
              <input type="text"  maxLength="22" name="title" onChange={this.handleChange} value={this.state.title} style={{"width": "50%"}} className="input" placeholder=""></input>
            </p>
          </div>
          <div className="field description__input">
            <label className="label">Description</label>
            <p className="control">
              <textarea type="text" name="text" onChange={this.handleChange} value={this.state.description} style={{"maxWidth": "50%", "minWidth": "50%"}} className="input" placeholder=""></textarea>
            </p>
          </div>

          <div className="field">
            <label className="label">Time Group</label>
            <p className="control">
              <div className="select">
                <select onChange={this.handleChange} value={this.state.time_group} name="time_group">
                  <option value="1">Up to 15 mins</option>
                  <option value="2">Up to 30 mins</option>
                </select>
              </div>
            </p>
          </div>

          <div className="field">
            <label className="label">Location</label>
            <p className="control">
              <div className="select">
                <select name="location" onChange={this.handleChange} value={this.state.location}>
                  <option value="1">Inside</option>
                  <option value="2">Outside</option>
                  <option value="3">Inside or Outside</option>
                </select>
              </div>
            </p>
          </div>

          <div className="field">
            <label className="label">Age Group</label>
            <p className="control">
              <input type="number" name="age_min" onChange={this.handleChange} value={this.state.age_min} style={{"width": "10%"}} className="input" placeholder="0"></input>
              <span style={{"lineHeight": "40px", "marginLeft": "5px", "marginRight": "5px", "verticalAlign": "top"}}>-</span>
              <input type="number" name="age_max" onChange={this.handleChange} value={this.state.age_max} style={{"width": "10%"}} className="input" placeholder="99"></input>
            </p>
          </div>


          <div className="field">
            <label className="label">Primary Image</label>
            <p className="control">
                <FileUpload onChange={this.handleChange} />
            </p>

            <img src={this.state.image} width="100px" height="100px"></img>

          </div>

          <MaterialInput existing={this.state.activity_existing_materials} onChange={this.materialsChanged} />

        {
            (this.state.hasloaded ? <InterestInput existing={this.state.activity_exitsting_interests} onChange={this.handleChange} /> : "")
        }
          
        {
            (this.state.hasloaded ? <LearningInput onChange={this.handleChange} existing={this.state.learning_benefits} /> : "")
        }
          
        {
            (this.state.hasloaded ?  <StepInput onChange={this.handleChange} materials={this.state.materials} existing={this.state.steps} /> : "")
        }
         
         <button className={this.state.formissubmitting? 'button is-primary is-loading': 'button is-primary'}  type="submit" style={{"marginTop": "50px", "float": "right"}}>Save Activity</button>



        </form>

        <button onClick={this.deleteActivity} className={this.state.isDeleting ? 'button is-primary is-danger': 'button is-danger'} style={{"marginTop": "50px", "float": "left"}}>Delete Activity</button>


      </Section>
    )
  }
}


export default EditActivity;
