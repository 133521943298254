import React from 'react';
import axios from 'axios';

import MaterialInput from './MaterialInput';
import FileUpload from './FileUpload';
import MaterialList from './MaterialList';

class StepInput extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            steps: (props.existing ? props.existing : []),
            materialsava: (props.materials ? props.materials : []),
            mounted: false,
            text_input: "",
            image_input_path: "",
            materials_input: [],
            editingStep: false,
            step_editing: null
        }

        this.materialInput = React.createRef();
        this.fileInput = React.createRef();
        this.editForm = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.deleteStep = this.deleteStep.bind(this);
        this.editStep = this.editStep.bind(this);
    }
    static getDerivedStateFromProps(nextProps, prevState){
        return {
            materialsava: nextProps.materials
        }
    }
    handleChange(e){
        if(e.target.name == "material_input"){
            this.setState({materials_input: e.value});
        }

        if(e.target.name == "text_input"){
            return this.setState({
                text_input: e.target.value
            })
        }

        if(e.target.name == "file_input"){
            return this.setState({
                image_input_path: e.value
            });
        }
    }
    handleSubmit(e){
        e.preventDefault();

        if(e.target.className == "file__upload") return;

        if(this.state.text_input.length > 0 && this.state.image_input_path !== ""){
            let newSteps = this.state.steps;


            if(this.state.editingStep){
                this.state.steps[this.state.step_editing] = {
                    text: this.state.text_input,
                    image: this.state.image_input_path,
                    materials: this.state.materials_input
                };
            }else{
                newSteps.push({
                    text: this.state.text_input,
                    image: this.state.image_input_path,
                    materials: this.state.materials_input
                });
            }

            

            this.setState({
                steps: newSteps,
                text_input: "",
                materials_input: [],
                step_editing: null,
                editingStep: false,
                image_input_path: ""
            });



            this.fileInput.current.reset();
            this.materialInput.current.reset();

            if(this.props.onChange){
                this.props.onChange({
                    "target":{
                        "name": "step_input"
                    },
                    "value": this.state.steps
                });
            }

        }
    }

    editStep(e){

        

        this.setState({
            editingStep: true,
            image_input_path: e.target.attributes.getNamedItem('data-image').value,
            step_editing: parseInt(e.target.attributes.getNamedItem('data-index').value),
            text_input: e.target.attributes.getNamedItem('data-text').value,
            materials_input: []
        });

        window.scrollTo(0, this.editForm.current.offsetTop);
       // console.log(this.state);
       
    }
    deleteStep(index){

        let newSteps = this.state.steps;
        newSteps.splice(index, 1);

        this.setState({
            steps:newSteps
        })

        if(this.props.onChange){
            this.props.onChange({
                "target":{
                    "name": "step_input"
                },
                "value": this.state.steps
            });
        }

    }
    render(){
        return (
            <div className="step__list">
                <div className="label">Steps</div>


                <div className="step__list__container">

                    {this.state.steps.map((e,i) => (
                        <div className="card">
                            <header className="card-header">
                                <p className="card-header-title">
                                    Step {i + 1}
                                    <MaterialList materials={e.materials} />
                                </p>
                                <a href="#" className="card-header-icon" aria-label="more options">
                                <span className="icon">
                                    <i className="fas fa-angle-down" aria-hidden="true"></i>
                                </span>
                                </a>
                            </header>
                            <div className="card-content">
                            <div className="content">
                                <div className="left">
                                <img src={e.image}></img>
                                </div>
                                
                                {e.text}
            
                            </div>
                            </div>
                            <footer className="card-footer">
                                <a onClick={e => this.deleteStep(i)} className="card-footer-item">Delete</a>
                                <a onClick={this.editStep} data-text={e.text} data-image={e.image} data-index={i}  className="card-footer-item">Edit</a>
                            </footer>
                        </div>
                    ))}

                    
                </div>

                <div className="step__add card" ref={this.editForm}>
                    <form onSubmit={this.handleSubmit} className="card-content">
                        <div className="field">
                            <label className="label">Text</label>
                            <p className="control">
                                <input type="text" name="text_input" onChange={this.handleChange} value={this.state.text_input} style={{"width": "50%"}} className="input" placeholder=""></input>
                            </p>
                        </div>
                        <div className="field">
                            <label className="label">Image</label>
                            <FileUpload ref={this.fileInput} onChange={this.handleChange} />
                        </div>

                        <img src={this.state.image_input_path} width="100px" height="100px"></img>

                        <MaterialInput ref={this.materialInput} onChange={this.handleChange} materials={this.state.materialsava} />
                        <span style={{"fontSize": "14px"}}>{this.state.editingStep ? "When editing a step, you must reselect materials associated with that step.": ""}</span> <br />
                        <button className="button is-primary">{this.state.editingStep ? 'Save Step':'Add Step'}</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default StepInput;