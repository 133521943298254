import React from 'react';



class MaterialList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            width: (this.props.size ? this.props.size : '25px'),
            height: (this.props.size ? this.props.size : '25px')
        }
    }
    render(){
        return (

        <td>{this.props.materials.map(e => {
            if(e){

            
            if(e.img){
                return (
                    <img src={process.env.REACT_APP_SERVER + e.img} width={this.state.width} height={this.state.height}></img>
                )
            }
        }
           
        })}</td>
        )
    }
}

export default MaterialList;