import React from 'react';
import '../../App.js';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import Axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';

import { CSVLink, CSVDownload } from "react-csv";



import {  Table, Section, Box, Level, Button } from "react-bulma-components";


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class SessionTime extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      values: [],
      search: "",
      searchResults: [],
      formatted: []
    }
    this.componentDidMount = this.componentDidMount.bind(this);
    this.getRecords = this.getRecords.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

  }

  componentDidMount(){
    this.getRecords();
  }

  getRecords(){

    Axios.get(`${process.env.REACT_APP_SERVER}/api/admin/stats/session`, {withCredentials: true}).then((e) => {
      
      let formatted = [];

      e.data.data.forEach((e, i) => {
        e.session_length = Math.abs(moment(e.created_at).diff(e.value, 'minutes'));
        formatted.push(e);
      })
      
      this.setState({
        values: e.data.data,
        searchResults: e.data.data,
        formatted: formatted
      })
    }).catch((e) => {
      console.log(e);
    })

  }
  handleSearch(e){
    
    this.setState({
      search: e.target.value
    })

    if(e.target.value === "" || e.target.value === " "){
      this.setState({searchResults: this.state.values});
      return;
    }
    
    let filt = this.state.values.filter(obj => obj.activity_title.toLowerCase().includes(this.state.search.toLowerCase()));
    this.setState({searchResults: filt});
  }
  render(){
    return (
      <Section>
        <Box>
              <Level renderAs="nav">
                  <Level.Side align="left">
                      <Level.Item>
                          <h1>Session Time Spent</h1>
                          <Button onClick={this.getRecords} style={{"marginLeft": "10px"}}>Refresh</Button>
                      </Level.Item>
                  </Level.Side>
                  <Level.Side align="center">
    <span>{this.state.searchResults.length} Record(s)</span>
                  </Level.Side>
                  <Level.Side align="right">
                      <Level.Item>

                      <CSVLink className="button is-primary" filename={"session-time.csv"} data={this.state.formatted}>
                        Download CSV
                    </CSVLink>

                      </Level.Item>
                  </Level.Side>
              </Level>
        </Box>


        <Table className="stat__table">
          <thead>
            <tr>
              <th>Session ID</th>
              <th>Device ID</th>
              <th>Session Length</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.searchResults.map(e => (
                <tr>
                  <td>{e.uuid}</td>
                  <td>{e.device_uuid}</td>
                  <td><Moment onChange={(val) => {console.log(val)}} diff={e.created_at} unit="minutes">{e.value}</Moment> Minutes</td>
                  <td><Moment fromNow>{e.created_at}</Moment></td>
                </tr>
              ))
            }
          </tbody>
        </Table>

      </Section>
    )
  }
}


export default SessionTime;
