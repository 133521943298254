import React from 'react';
import './App.css';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import axios from 'axios';
import 'react-notifications/lib/notifications.css';




import { Button, Section, Container, Columns, Menu, Loader  } from "react-bulma-components";

import Statistics from './views/statistics';
import Activites from './views/activities';
import ActivityList from './views/activity/activityList';
import NewActivity from './views/activity/addNewActivity';
import ActivityTime from './views/stats/activityTime';
import Devices from './views/stats/devices';
import SessionTime from './views/stats/sessionTime';
import Login from './views/login';
import EditActivity from './views/activity/editActivity';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class App extends React.Component{
  constructor(){
    super();
    this.state = {
      currentRoute: 'activities',
      user: null,
      isLoggedIn: null
    }
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.getAllInterests = this.getAllInterests.bind(this);
  }


  componentDidMount(){
    this.isLoggedIn();
    
  }

  isLoggedIn(){
    axios.get(process.env.REACT_APP_SERVER + '/api/admin/me', {withCredentials: true}).then((e) => {
      
      this.setState({
        isLoggedIn: true,
        user: e.data.data
      })

      this.getAllInterests();
     

    }).catch((e) => {
      console.log(e);
      if(!e.response) return;

      
    
      if(e.response.status   == 401){
   
        this.setState({
          user: null,
          isLoggedIn: false
        });
      }
    })
  }

  getAllInterests(){
    axios.get(process.env.REACT_APP_SERVER + '/api/admin/interests', {withCredentials: true}).then((e) => {

        this.setState({
          interests: e.data.data
        });

    }).catch((e) => {
      if(e.response.status == 401){
   
        this.setState({
          user: null,
          isLoggedIn: false
        });
      }
    })
  }

  

  render(){
    if(this.state.isLoggedIn == false){
      return (
        <Login isLoggedIn={this.isLoggedIn} />
      )
    }
    if(this.state.isLoggedIn == null){
      return (
        <Loading />
      )
    }



    return (
      <Router>
        <Section>
          <Container>
            <header>
              <h1 className="subtitle">
                KidsWantU - Dashboard
              </h1>
              <span>
                {this.state.user.email}
              </span>
            </header>

            <Columns className="main__content">
              <Columns.Column>
                <Menu>
               
                  <Menu.List title="Activities">
                 
                    <Link to="/new-activity" onClick={e => this.setState({currentRoute: 'new-activity'})} className={this.state.currentRoute == 'new-activity' ? 'is-active' : ''}>
                        Add New Activity
                      </Link>
                      <Link to="/" onClick={e => this.setState({currentRoute: 'activity-list'})} className={this.state.currentRoute == 'activity-list' ? 'is-active' : ''}>
                        Activity List
                      </Link>
                  </Menu.List>
                  <Menu.List title="Statistics">
                    <Link to="/devices" onClick={e => this.setState({currentRoute: 'devices'})} className={this.state.currentRoute == 'devices' ? 'is-active' : ''}>
                        Devices
                      </Link>
                      
                      <Link to="/activity-time" onClick={e => this.setState({currentRoute: 'activity-time'})} className={this.state.currentRoute == 'activity-time' ? 'is-active' : ''}>
                        Activity Time Spent
                      </Link>
                      <Link to="/session-time" onClick={e => this.setState({currentRoute: 'session-time'})} className={this.state.currentRoute == 'session-time' ? 'is-active' : ''}>
                        Session Time
                      </Link>
                  </Menu.List>
                </Menu>
              </Columns.Column>
              <Columns.Column className="main__section" size="four-fifths">
                <Switch>
                 
                  <Route exact path="/statistics">
                    <Statistics />
                  </Route>
                  <Route  exact path="/" render={
                    ({history}) => (
                      <ActivityList history={history} />
                    )
                  }>
                  </Route>
                  <Route  exact path="/new-activity" render={
                    ({history}) => (
                      <NewActivity history={history} />
                    )
                  }>
                  </Route>

                  <Route exact path="/edit/:uuid" render={
                    ({history, match}) => (
                    <EditActivity history={history} match={match} />
                    )
                  }>

                  </Route>


                  <Route exact path="/activity-time">
                    <ActivityTime />
                  </Route>
                  <Route exact path="/devices">
                    <Devices />
                  </Route>
                  <Route exact path="/session-time">
                    <SessionTime />
                  </Route>
                  
                  
                </Switch>
              </Columns.Column>
            </Columns>
            
          </Container>
        </Section>
      </Router>
    )
  }
}

function Loading(props){
  return (
    <Container style={{textAlign: 'center', paddingTop: '100px'}}>
      <Loader style={{ width: 50, height: 50, border: '5px solid blue', borderTopColor: 'transparent', borderRightColor: 'transparent', display: 'inline-block' }} />
      <br /><br /><br /><br /><br /><br />
      <span>Just a second...</span>
    </Container>
  
  );
}

export default App;
