import React from 'react';
import '../App.css';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import axios from 'axios';

import { Button, Section, Container,  Card, Label, Control,Input, Notification } from "react-bulma-components";


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class Login extends React.Component{
  constructor(props){
    super(props);

    this.state = {
        value: "",
        email: "",
        password: ""
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleChange(event) {
    
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit(event){
    event.preventDefault();
    
    axios.post(process.env.REACT_APP_SERVER + '/login', {
        username: this.state.email,
        password: this.state.password
    }, {withCredentials: true}).then((e) => {
        
        this.props.isLoggedIn();

    }).catch((e) => {
        
        this.setState({
            error: "Invalid username or password"
        });

    })


  }

  render(){
    return (
      <Section size="large">
          <Container style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
              <Card style={{width: '400px'}}>
                  <Card.Header>
                    <Card.Header.Title>Login</Card.Header.Title>
                  </Card.Header>
                  <Card.Content>
                      
                        <form onSubmit={this.handleSubmit}>


                        {this.state.error != null ? <Notification color="danger">{this.state.error}</Notification> : ""}

                        <div className="field">
                            <label className="label">Email</label>
                            <div className="control">
                                <input className="input" name="email" type="email" placeholder="Email input" value={this.state.email} onChange={this.handleChange}></input>
                                
                            </div>
                            
                            </div> 


                            <div className="field">
                            <label className="label">Password</label>
                                <p className="control ">
                                    <input className="input" name="password" type="password" placeholder="Password" value={this.state.password} onChange={this.handleChange}></input>
                                    <span className="icon is-small is-left">
                                    <i className="fas fa-lock"></i>
                                    </span>
                                </p>
                                </div>
                                <div className="field">
                                    <p className="control">
                                        <button type="submit" className="button is-success">
                                        Login
                                        </button>
                                    </p>
                                    </div>
                        </form>          
                    
                  </Card.Content>
              </Card>
          </Container>
      </Section>
    )
  }
}


export default Login;
